import * as React from "react";
import { Interface } from "../Interface/sharedInterface";

import "../Styles/Item.css";
export const Item = (props: { item: Interface.Item, openPopup: (event: React.MouseEvent<HTMLDivElement>) => void }) => (
    <div className="ItemContainer" onClick={ props.openPopup }>
        <table><tbody>
            <tr>
                <td className="ItemInfo">
                    <table><tbody>
                        <tr><td>名稱: { props.item.name }</td></tr>
                        <tr><td>編號: { props.item.id }</td></tr>
                        <tr><td>來源: { props.item.source }</td></tr>
                        <tr><td>尺寸: { props.item.size }</td></tr>
                        <tr><td>重量: { props.item.weight }</td></tr>
                        <tr><td>材料: { props.item.material }</td></tr>
                        <tr><td>現價: { props.item.stockRecords.in.slice().pop()?.refSellPrice || 0 }</td></tr>
                        <tr><td>存貨: {
                            props.item.stockRecords.in.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0)
                            - props.item.stockRecords.out.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0) }
                        </td></tr>
                        <tr><td>售出: { props.item.stockRecords.out.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0) }</td></tr>
                    </tbody></table>
                </td>
                <td className="ItemThumbnail">
                    <div className="FakeImage" style ={ { backgroundImage: `url('${props.item.img}')` } } />
                </td>
            </tr>
        </tbody></table>
    </div>
)
