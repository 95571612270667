import * as React from "react";
import "../Styles/Item.css";

export const AddItem = (props: { openPopup: (event: React.MouseEvent<HTMLDivElement>) => void }) => (
    <div className="ItemContainer" onClick={ props.openPopup }>
        <table><tbody>
            <tr>
                <td className="AddItem">+</td>
            </tr>
        </tbody></table>
    </div>
)
