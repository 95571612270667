import * as React from "react";
import { Interface } from "../Interface/sharedInterface";
import "../Styles/DetailedInfo.css";
import "../Styles/Item.css";
import FileBox from "./FileBox";
import StockRecord from "./StockRecord";
import Remarks from "./Remarks";

export class DetailedInfo extends React.Component<
    {
        itemInfo: Interface.Item,
        onClose: (event: React.MouseEvent<HTMLDivElement>) => void,
        mode: string,
        onAddItem: Function,
        onEditItem: Function,
        onDeleteItem: Function,
        onAddRecord: Function,
        onRemoveRecord: Function
    },
    {
        item: Interface.Item,
        fileInputRef: React.RefObject<HTMLInputElement>,
        file: File | null,
        mode: string
    }
> {
    state = {
        item: {
            name: this.props.itemInfo.name,
            id: this.props.itemInfo.id,
            source: this.props.itemInfo.source,
            size: this.props.itemInfo.size,
            weight: this.props.itemInfo.weight,
            material: this.props.itemInfo.material,
            remarks: this.props.itemInfo.remarks,
            stockRecords: this.props.itemInfo.stockRecords,
            img: this.props.itemInfo.img,
        },
        file: null,
        fileInputRef: React.createRef<HTMLInputElement>(),
        mode: this.props.mode
    }

    tempItem: Interface.Item = {...this.state.item};
    _tempImg: string = "";

    onInput(e: React.FormEvent<HTMLInputElement>) {
        let item: Interface.Item = Object.assign({}, this.state.item);
        item = Object.assign(item, { [e.currentTarget.name as string]: e.currentTarget.value});
        this.setState({ item: item });
    }

    async onInputRemarks(e: React.FormEvent<HTMLTextAreaElement>) {
        let item: Interface.Item = {...this.state.item};
        item = Object.assign(item, { remarks: e.currentTarget.value});
        this.setState({ item: item });
    }

    async onConfirm() {
        switch (this.state.mode) {
            case "NEW":
                if (this.state.item.id === "") {
                    alert("未輸入編號!");
                    return;
                }
                let addSucceed = await this.props.onAddItem(this.state.item, this.state.file);
                addSucceed && this.setState({ mode: "DETAIL" });
            break;
            case "EDIT":
                this.props.onEditItem(this.state.item, this.state.file);
                this.setState({ mode: "DETAIL" });
            break;
        }
    }

    onCancel() {
        this.tempItem.img = this._tempImg;
        this._tempImg = "";
        this.setState({
            mode: "DETAIL",
            item: {...this.tempItem}
        });
    }

    onClearImage() {
        let copiedItem = {...this.state.item};
        copiedItem.img = "";
        this.setState({ item: copiedItem });
    }

    onEdit() {
        this._tempImg = this.state.item.img;
        this.tempItem = {...this.state.item};
        this.setState({ mode: "EDIT" });
    }

    onDelete() {
        this.props.onDeleteItem(this.state.item);
    }

    onAddRecord(record: Interface.In | Interface.Out) {
        this.props.onAddRecord(this.state.item, record);
    }

    onRemoveRecord(type: string, key: number, ) {
        this.props.onRemoveRecord(type, key, this.state.item);
    }

    switchMode() {
        let jsx!: JSX.Element;
        switch (this.state.mode) {
            case "NEW":
                jsx = this.renderNew();
            break;
            case "EDIT":
                jsx = this.renderEdit();
            break;
            case "DETAIL":
                jsx = this.renderDetail();
            break;
        }
        return jsx;
    }

    renderNew() {
        return (
            <tr>
                <td id="DetailedItemInfo">
                    <table><tbody>
                        <tr><td>名稱: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="name" autoComplete="off" placeholder="輸入..."/></td></tr>
                        <tr><td>編號: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="id" autoComplete="off" placeholder="輸入..."/></td></tr>
                        <tr><td>來源: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="source" autoComplete="off" placeholder="輸入..."/></td></tr>
                        <tr><td>尺寸: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="size" autoComplete="off" placeholder="輸入..."/></td></tr>
                        <tr><td>重量: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="weight" autoComplete="off" placeholder="輸入..."/></td></tr>
                        <tr><td>材料: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="material" autoComplete="off" placeholder="輸入..."/></td></tr>
                        <tr><td></td><td colSpan={2}><button onClick={ this.onConfirm.bind(this) }>確定</button></td></tr>
                    </tbody></table>
                </td>
                <td id="DetailedItemImage">
                    <FileBox image={ this.state.item.img ? this.state.item.img : "" } refer={ this.state.fileInputRef } onChange={ this.onChange.bind(this) } onDrop={ this.updateItemWithFile.bind(this) }/>
                </td>
            </tr>
        )
    }

    onChange() {
        let file = (this.state.fileInputRef.current?.files as FileList)[0];
        this.updateItemWithFile(file);
    }

    updateItemWithFile(file: File) {
        let updateItem = {...this.state.item};
        updateItem.img = URL.createObjectURL(file);
        this.setState({
            file: file,
            item: updateItem
        });
    }

    renderEdit() {
        let sold = this.state.item.stockRecords.out.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
        let stock = this.state.item.stockRecords.in.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0) - sold;
        let currentPrice = this.state.item.stockRecords.in.slice().pop()?.refSellPrice || 0;
        return (
            <tr>
                <td id="DetailedItemInfo">
                    <table><tbody>
                        <tr><td>名稱: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="name" autoComplete="off" value={ this.state.item.name }/></td></tr>
                        <tr><td>編號: </td><td colSpan={3}>{ this.state.item.id }</td></tr>
                        <tr><td>來源: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="source" autoComplete="off" value={ this.state.item.source }/></td></tr>
                        <tr><td>尺寸: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="size" autoComplete="off" value={ this.state.item.size }/></td></tr>
                        <tr><td>重量: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="weight" autoComplete="off" value={ this.state.item.weight }/></td></tr>
                        <tr><td>材料: </td><td colSpan={3}><input type="text" onChange={ this.onInput.bind(this) } name="material" autoComplete="off" value={ this.state.item.material }/></td></tr>
                        <tr><td>現價: </td><td colSpan={3}>{ currentPrice }</td></tr>
                        <tr><td>存貨: </td><td colSpan={3}>{ stock }</td></tr>
                        <tr><td>售出: </td><td colSpan={3}>{ sold }</td></tr>
                        <tr>
                            <td colSpan={2}><button onClick={ this.onConfirm.bind(this) }>Confirm</button></td>
                            <td colSpan={2}><button onClick={ this.onCancel.bind(this) }>Cancel</button></td>
                        </tr>
                    </tbody></table>
                </td>
                <td id="DetailedItemImage">
                    <FileBox image={this.state.item.img}  refer={ this.state.fileInputRef } onChange={ this.onChange.bind(this) }/>
                    { this.state.item.img !== "" && <button id="ClearImageBtn" onClick={ this.onClearImage.bind(this)}>重設圖片</button> }
                </td>
            </tr>
        )
    }

    renderDetail() {
        let sold = this.state.item.stockRecords.out.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
        let stock = this.state.item.stockRecords.in.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0) - sold;
        let currentPrice = this.state.item.stockRecords.in.slice().pop()?.refSellPrice || 0;
        return (
            <tr>
                <td id="DetailedItemInfo">
                    <table><tbody>
                        <tr><td>名稱: </td><td colSpan={3}>{ this.state.item.name }</td></tr>
                        <tr><td>編號: </td><td colSpan={3}>{ this.state.item.id }</td></tr>
                        <tr><td>來源: </td><td colSpan={3}>{ this.state.item.source }</td></tr>
                        <tr><td>尺寸: </td><td colSpan={3}>{ this.state.item.size }</td></tr>
                        <tr><td>重量: </td><td colSpan={3}>{ this.state.item.weight }</td></tr>
                        <tr><td>材料: </td><td colSpan={3}>{ this.state.item.material }</td></tr>
                        <tr><td>現價: </td><td colSpan={3}>{ currentPrice }</td></tr>
                        <tr><td>存貨: </td><td colSpan={3}>{ stock }</td></tr>
                        <tr><td>售出: </td><td colSpan={3}>{ sold }</td></tr>
                        <tr><td colSpan={2}><button onClick={ this.onEdit.bind(this) }>編輯</button></td><td colSpan={2}><button id="DeleteBtn" onClick={ this.onDelete.bind(this) }>刪除</button></td></tr>

                    </tbody></table>
                </td>
                <td id="DetailedItemImage">
                    <FileBox image={this.state.item.img} />
                </td>
            </tr>
        )
    }

    switchRemarksMode() {
        let jsx!: JSX.Element | null;
        switch (this.state.mode) {
            case "NEW":
                jsx = null;
            break;
            case "EDIT":
                jsx = <Remarks content={ this.state.item.remarks } onInputChange={ this.onInputRemarks.bind(this) }/>;
            break;
            case "DETAIL":
                jsx = <Remarks content={ this.state.item.remarks } />;
            break;
        }
        return jsx;
    }

    render() {
        return (
            <div id="DetailedInfo" onClick={ this.props.onClose }>
                { this.state.mode !== "NEW" &&
                    <StockRecord
                        onAddRecord={ this.onAddRecord.bind(this) }
                        onRemoveRecord={ this.onRemoveRecord.bind(this) }
                        ins={ this.state.item.stockRecords.in }
                        outs={ this.state.item.stockRecords.out }
                    />
                }
                <div id="DetailedPanel" onClick={ (e) => e.stopPropagation() }>
                    <table><tbody>
                        { this.switchMode() }
                    </tbody></table>
                </div>
                { this.switchRemarksMode() }
            </div>
        )
    }
}
