import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/storage";
import { Interface } from "../Interface/sharedInterface";

export default class FirebaseManager {

    private static _instance: FirebaseManager;
    _itemListener!: () => void;

    static get instance() {
        if (!this._instance) {
            this._instance = new FirebaseManager();
        }
        return this._instance;
    }

    auth: firebase.auth.Auth;
    firestore: firebase.firestore.Firestore;
    storage: firebase.storage.Storage;

    private constructor() {
        var firebaseConfig = {
          apiKey: "AIzaSyCJjBhXt5TWq8OglFXRqUPnAG6pcMo18V4",
          authDomain: "artfile-database.firebaseapp.com",
          databaseURL: "https://artfile-database.firebaseio.com",
          projectId: "artfile-database",
          storageBucket: "artfile-database.appspot.com",
          // messagingSenderId: "703056278128",
          appId: "1:703056278128:web:2ea96cab6b6e6afddf7ab5",
          // measurementId: "G-J316CS0XWS"
        };
        // Initialize Firebase
        firebase.initializeApp(firebaseConfig);

        this.auth = firebase.auth();
        this.firestore = firebase.firestore();
        this.storage = firebase.storage();
        console.log("firebase initialized!");
    }

    private getReference(type: string, id?: string) {
        if (id) return this.firestore.collection("Stock").doc("Stocks").collection(type).doc(id);
        else return this.firestore.collection("Stock").doc("Stocks").collection(type);
    }

    static initFirebase() {
        if (!FirebaseManager._instance) {
            console.log("init Firebase");
            FirebaseManager._instance = new FirebaseManager();
        }
    }

    async createUser(email: string, password: string) {
        let newUserProm = await this.auth.createUserWithEmailAndPassword(email, password);
        console.log(newUserProm);
    }

    async signIn(email: string, password: string) {
        let signInProm = await this.auth.signInWithEmailAndPassword(email, password);
        return signInProm;
    }

    async getTypes() {
        interface Types {
            type: Array<string>
        }
        let converter: firebase.firestore.FirestoreDataConverter<Types> = {
            toFirestore: () => { return {} },
            fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot, option: firebase.firestore.SnapshotOptions) => {
                return { type: snapshot.data(option).type };
            }
        }
        let getTypesProm = await this.firestore.collection("Stock").doc("Stocks").withConverter(converter).get();
        return getTypesProm.data()!.type;
    }

    toggleItemListener(type: string, toggle: boolean, cb?: Function) {
        if (toggle && cb !== undefined) this._itemListener = (this.getReference(type) as firebase.firestore.CollectionReference).onSnapshot(() => { cb(); });
        else if (!toggle && this._itemListener !== undefined) this._itemListener();

    }

    async getItems(type: string) {
        let converter: firebase.firestore.FirestoreDataConverter<Interface.Item> = {
            toFirestore: () => { return {} },
            fromFirestore: (snapshot: firebase.firestore.QueryDocumentSnapshot, option: firebase.firestore.SnapshotOptions) => {
                return {
                    id: snapshot.id,
                    name: snapshot.data(option).name,
                    source: snapshot.data(option).source,
                    size: snapshot.data(option).size,
                    weight: snapshot.data(option).weight,
                    material: snapshot.data(option).material,
                    inStock: snapshot.data(option).inStock,
                    currentPrice: snapshot.data(option).currentPrice,
                    sold: snapshot.data(option).sold,
                    remarks: snapshot.data(option).remarks,
                    img: snapshot.data(option).img,
                    stockRecords: snapshot.data(option).stockRecords,
                };
            }
        }
        let itemArray: Array<Interface.Item> = [];
        let getItemsProm = await (this.getReference(type) as firebase.firestore.CollectionReference).withConverter(converter).get();
        getItemsProm.docs.forEach(doc => {
            itemArray.push(doc.data());
        })
        return itemArray;
    }

    async addItem(type: string, item: Interface.Item, imageFile: File) {
        let uploadItem = {...item};
        let id = uploadItem.id; //need for reference
        delete uploadItem.id; //don't need in firestore
        if ((await (this.getReference(type, id) as firebase.firestore.DocumentReference).get()).exists) {
            alert("編號已存在!");
            return false;
        }
        let imageUrl = imageFile ? await this.uploadImage(type, imageFile, id as string) : "https://via.placeholder.com/150";
        uploadItem.img = imageUrl;
        item.img = imageUrl; //modify the reference
        await (this.getReference(type, id) as firebase.firestore.DocumentReference).set(uploadItem);
        return true;
    }

    async editItem(type: string, item: Interface.Item, imageFile?: File) {
        let imageUrl = imageFile ? await this.uploadImage(type, imageFile, item.id as string) : item.img ? item.img : "https://via.placeholder.com/150";
        item.img = imageUrl;
        let editItem = {...item};
        let id = editItem.id;
        delete editItem.id;
        await (this.getReference(type, id) as firebase.firestore.DocumentReference).set(item);
    }

    async deleteItem(type: string, id: string) {
        await this.storage.ref().child(type).child(id).delete().catch(() => {});
        await (this.getReference(type, id) as firebase.firestore.DocumentReference).delete();
    }

    async uploadImage(type: string, file: File, fileName: string) {
        let fileRef = this.storage.ref().child(type).child(fileName);
        await fileRef.put(file);
        let imageUrl = await fileRef.getDownloadURL();
        return imageUrl;
    }

    async updateRecord(type: string, item: Interface.Item, updateRecord: { ins?: Interface.In, outs?: Interface.Out}) {
        let updateItem = {...item};
        updateRecord.ins && updateItem.stockRecords.in.push(updateRecord.ins);
        updateRecord.outs && updateItem.stockRecords.out.push(updateRecord.outs);
        let id = updateItem.id;
        delete updateItem.id;
        console.log(updateItem);
        await (this.getReference(type, id) as firebase.firestore.DocumentReference).set(updateItem);
    }

    async removeRecord(type: string, item: Interface.Item, stockType: string, key: number) {
        let updateItem: Record<string, any> = {...item};
        updateItem.stockRecords[stockType].splice(key, 1);
        let id = updateItem.id;
        delete updateItem.id;
        await (this.getReference(type, id) as firebase.firestore.DocumentReference).set(updateItem);
    }
}
