import * as React from "react";
import "../Styles/FileBox.css";
export default class FileBox extends React.Component<{onChange?: ((event: React.ChangeEvent<HTMLInputElement>) => void), onDrop?: Function, refer?: React.RefObject<HTMLInputElement>, image?: string}, { isDragging: boolean }> {
    state = {
        isDragging: false
    }

    dragCounter: number = 0;

    renderUpload() {
        return (
            <div className={`FileInput${this.state.isDragging ? " dragging" : ""}`}>
            {
                this.props.refer !== undefined && this.props.image === "" &&
                <input className="UploadInput" type="file" accept="image/*" ref={this.props.refer} onChange={this.props.onChange}/>
            }
            </div>
        )
    }

    renderPending() {
        return (
            <div className={`FileInput${this.state.isDragging ? " dragging" : ""}`}>
                <div className="FileImage" style ={ { backgroundImage: `url('${this.props.image}')` } } />
            </div>
        )
    }

    renderImage() {
        return (
            <div className="FileImage" style ={ { backgroundImage: `url('${this.props.image}')` } } />
        )
    }

    handleDragEnter(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        this.dragCounter++;
        this.setState({ isDragging: true });
    }

    handleDragLeave(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        this.dragCounter--;
        if (this.dragCounter > 0) return;
        this.setState({ isDragging: false });
    }

    handleDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
    }

    handleDrop(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        this.dragCounter = 0;
        this.setState({ isDragging: false });
        if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
            this.props.onDrop && this.props.onDrop(e.dataTransfer.files[0]);
            e.dataTransfer.clearData();
        }
    }

    render() {
        return (
            <div className="FileBox" onDragEnter={this.handleDragEnter.bind(this)} onDragLeave={this.handleDragLeave.bind(this)} onDragOver={this.handleDragOver.bind(this)} onDrop={this.handleDrop.bind(this)}>
                {
                    this.props.image !== "" ?
                        (this.props.image?.startsWith("blob") ?
                            this.renderPending() : this.renderImage()
                        ) : this.renderUpload()
                }
            </div>
        )
    }
}
