import * as React from "react";
import { Redirect } from "react-router-dom";
import "../Styles/Dashboard.css";
import { InventoryContainer } from "./InventoryContainer";
import FirebaseManager from "../Script/FirebaseManager";

export class Dashboard extends React.Component<{ isAuth: boolean }, { categories: Array<string>, currentCategorie: string}> {
    state = {
        categories: [],
        currentCategorie: ""
    }

    buttonList(list: Array<string>) {
        return list.map(item => <button type="button" className="CategoryButton" onClick={ this.onSelectCategory.bind(this) } key={ item } value={ item }>{ item }</button>);
    }

    onSelectCategory(event: React.MouseEvent<HTMLButtonElement>) {
        this.setState({ currentCategorie: event.currentTarget.value})
    }

    onReturnCategory() {
        this.setState({ currentCategorie: ""});
    }

    dashboard() {
        FirebaseManager.instance.getTypes().then(types => {
            this.setState({ categories: types });
        });
        return (
            <div id="CategorySelectContainer">
                <h1>選擇類別</h1>
                <div id="CategorySelections">
                    { this.buttonList(this.state.categories) }
                </div>
            </div>
        )
    }

    inventory() {
        // console.log("oops");
        return <InventoryContainer type={this.state.currentCategorie} onReturn={this.onReturnCategory.bind(this)}/>;
    }

    redirectLogin() {
        return <Redirect to="/login"></Redirect>;
    }

    render() {
        return (
            <div className="Dashboard">
                { this.props.isAuth? (this.state.currentCategorie === "" ? this.dashboard() : this.inventory()) : this.redirectLogin() }
            </div>
        )
    }
}
