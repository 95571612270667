import * as React from "react";
import { Interface } from "../Interface/sharedInterface";
import { Redirect } from "react-router-dom";
import "../Styles/LoginPanel.css";
import FirebaseManager from "../Script/FirebaseManager";

export class LoginPanel extends React.Component<{authUser: Function}, Interface.LoginPanel> {
    state: Interface.LoginPanel = {
        account: "",
        password: "",
        message: "",
        isLoggedIn: false
    }

    onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        let target = event.currentTarget;
        this.setState(prevState => ({
            ...prevState,
            [target.id]: target.value
        }));
    }

    onLogin = async () => {
        this.props.authUser();
        FirebaseManager.instance.signIn("hksahenry@yahoo.com.hk", "hksa13968629").then(
            (data: firebase.auth.UserCredential) => {
                this.setState({ isLoggedIn: true });
            },
            (error: firebase.auth.Error) => {
                this.setState({ message: error.code})
            }
        );
    }
/*
    <div><h1 id="loginTitle">登入</h1></div>
    <div className="inputRow">
        <p className="loginInputLabel">登入賬戶</p>
        <input type="text" className="loginInputBox" id="account" autoComplete="off" value={ this.state.account } onChange={ this.onInputChange }/>
    </div>
    <div className="inputRow">
        <p className="loginInputLabel">密碼</p>
        <input type="password" className="loginInputBox" id="password" value={ this.state.password } onChange={ this.onInputChange }/>
    </div>
    <div>
        <button type="button" className="loginButton" onClick={ this.onLogin }>確定</button>
        { this.state.isLoggedIn && <Redirect to="/"></Redirect> }
    </div>
    <div>{this.state.message}</div>
*/
    render() {
        return (
            <div id="LoginPage">
                <div />
                <div className="PanelCol">
                    <div />
                    <div className="LoginPanel">
                        <div><h1 id="loginTitle">登入</h1></div>
                        <div className="inputRow">
                            <div className="loginInputLabel">登入賬戶</div>
                                <input type="text" className="loginInputBox" id="account" autoComplete="off" value={ this.state.account } onChange={ this.onInputChange }/>
                            <div className="loginInputLabel">密碼</div>
                                <input type="password" className="loginInputBox" id="password" value={ this.state.password } onChange={ this.onInputChange }/>
                        </div>
                        <button type="button" className="loginButton" onClick={ this.onLogin }>確定</button>
                        { this.state.isLoggedIn && <Redirect to="/"></Redirect> }
                        <div>{this.state.message}</div>
                    </div>
                    <div />
                </div>
                <div />
            </div>
        )
    }
}
