import * as React from "react";
import "../Styles/StockRecord.css";
import { Interface } from "../Interface/sharedInterface";

export default class Record extends React.Component<
    { onAddRecord: Function, onRemoveRecord?: Function, checkAmount: Function, in?: Interface.In, out?: Interface.Out, refIn: Array<Interface.In>, type: string },
    { in?: Interface.In, out?: Interface.Out, editIn: Interface.In, editOut: Interface.Out, isEdit: boolean}
> {
    state = {
        in: this.props.in,
        out: this.props.out,
        editIn: {
            date: "",
            amount: "0",
            cost: "0",
            refSellPrice: "0",
        },
        editOut: {
            date: "",
            amount: "0",
            price: "0"
        },
        isEdit: false
    }

    onAddRecord(edit: { ins?: Interface.In, outs?: Interface.Out }) {
        let errors: Array<string> = [];
        if (!this.props.checkAmount(edit, 1)) errors.push("出入貨數量不正確! 入貨總數少於出貨總數!\n");
        let editRecord = edit.ins? edit.ins : edit.outs;
        let matchDate = /(0[1-9]|1[0-2])\/([012]\d$)/;
        let matchAmount = /^([0-9]|[1-9][0-9])$/;
        let matchMoney = /^(?!(?:0|0\.0|0\.00)$)?\d+(\.\d)?$/;
        if (editRecord) {
            if (!editRecord.date.match(matchDate)) errors.push("日期格式不正確! MM/YY\n");
            if (!editRecord.amount.match(matchAmount)) errors.push("出入貨數量格式不正確! 數量必須為整數!\n");
        }

        if (edit.ins) {
            if (!edit.ins.cost.match(matchMoney)) errors.push("入貨價格式不正確!\n");
            if (!edit.ins.refSellPrice.match(matchMoney)) errors.push("參考售價格式不正確!\n");
        }
        if (edit.outs)
            if (!edit.outs.price.match(matchMoney)) errors.push("售價格式不正確!\n");
        if (errors.length > 0) {
            alert(errors.reduce((complete, msg) => complete + msg, ""));
            return;
        }

        this.props.onAddRecord && this.props.onAddRecord(edit);
        this.setState({ isEdit: false });
    }

    onCancelAdd() {
        if (this.props.type === "in") this.setState({ isEdit: false, editIn: { date: "", amount: "0", cost: "0", refSellPrice: "0" }});
        else if (this.props.type === "out") this.setState({ isEdit: false, editOut: { date: "", amount: "0", price: "0" }});
    }

    renderRow() {
        if (this.state.in) return this.renderIn();
        else if (this.state.out) return this.renderOut();
        else if (this.state.isEdit) return this.renderEdit();
        else return this.renderAdd();
    }

    renderIn() {
        return <tr className="Record">
            <td>{this.state.in?.date}</td>
            <td>{this.state.in?.amount}</td>
            <td>{this.state.in?.cost}</td>
            <td>{this.state.in?.refSellPrice}</td>
            <td onClick={ this.props.onRemoveRecord && this.props.onRemoveRecord.bind(this) }>—</td>
        </tr>;
    }

    renderOut() {
        return <tr className="Record">
            <td>{this.state.out?.date}</td>
            <td>{this.state.out?.amount}</td>
            <td>{this.state.out?.price}</td>
            <td onClick={ this.props.onRemoveRecord && this.props.onRemoveRecord.bind(this) }>—</td>
        </tr>;
    }

    renderAdd() {
        return <tr className="Record"><td colSpan={this.props.type === "in" ? 5 : 4} onClick={ this.onClickedAdd.bind(this) }>+</td></tr>
    }

    onClickedAdd(){
        let refInArray: Array<Interface.In> = [...this.props.refIn];
        let refIn: Interface.In | boolean = refInArray.length > 0 && {...refInArray[refInArray.length - 1]};
        if (this.props.type === "in") {
            if (refIn) this.setState({ isEdit: true, editIn: refIn });
            else this.setState({ isEdit: true, editIn: { date: "", amount: "0", cost: "0", refSellPrice: "0" } });
        }
        else {
            if (refIn) this.setState({ isEdit: true, editOut: { date: "", amount: "0", price: refIn.refSellPrice } });
            else alert("存貨不足!");
        }
    }

    renderEdit() {
        if (this.props.type === "in") return (<tr className="Record">
            <td><input type="text" id="date" value={ this.state.editIn.date } autoComplete="off" onChange={ this.onInputChange }/></td>
            <td><input type="text" id="amount" value={ this.state.editIn.amount } autoComplete="off" onChange={ this.onInputChange }/></td>
            <td><input type="text" id="cost" value={ this.state.editIn.cost } autoComplete="off" onChange={ this.onInputChange }/></td>
            <td><input type="text" id="refSellPrice" value={ this.state.editIn.refSellPrice } autoComplete="off" onChange={ this.onInputChange }/></td>
            <td><div onClick={ this.onAddRecord.bind(this, { ins: this.state.editIn }) } className="SmallBtn">o</div><div onClick={ this.onCancelAdd.bind(this) } className="SmallBtn">x</div></td>
        </tr>);
        else if (this.props.type === "out") return (<tr className="Record">
            <td><input type="text" id="date" value={ this.state.editOut.date } autoComplete="off" onChange={ this.onInputChange }/></td>
            <td><input type="text" id="amount" value={ this.state.editOut.amount } autoComplete="off" onChange={ this.onInputChange }/></td>
            <td><input type="text" id="price" value={ this.state.editOut.price } autoComplete="off" onChange={ this.onInputChange }/></td>
            <td><div onClick={ this.onAddRecord.bind(this, { outs: this.state.editOut }) } className="SmallBtn">o</div><div onClick={ this.onCancelAdd.bind(this) } className="SmallBtn">x</div></td>
        </tr>);
    }

    onInputChange = (event: React.FormEvent<HTMLInputElement>) => {
        let target = event.currentTarget;
        let editObj = (this.props.type === "in"? { ...this.state.editIn, [target.id]: target.value } : { ...this.state.editOut, [target.id]: target.value });
        let edit = this.props.type === "in" ? "editIn" : "editOut";
        this.setState(prevState => ({
            ...prevState,
            [edit]: editObj
        }));
    }

    render() {
        return (
            this.renderRow()
        )
    }
}
