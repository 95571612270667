import * as React from "react";
import Record from "./Record";
import "../Styles/StockRecord.css";
import { Interface } from "../Interface/sharedInterface";

export default class StockRecord extends React.Component<{ onAddRecord: Function, onRemoveRecord: Function, ins?: Array<Interface.In>, outs?: Array<Interface.Out> }, {ins?: Array<Interface.In>, outs?: Array<Interface.Out>}> {
    state = {
        ins: this.props.ins as Array<Interface.In>,
        outs: this.props.outs as Array<Interface.Out>
    }

    onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
    }

    onRemoveRecord(type: string, key: number) {
        let edit: { ins?: Interface.In, outs?: Interface.Out } = {};
        if (type === "in") edit.ins = this.state.ins[key];
        else edit.outs = this.state.outs[key];
        if (!this.checkAmount(edit , -1)) {
            alert("出入貨數量不正確! 刪除後入貨總數少於出貨總數!");
            return;
        }
        this.props.onRemoveRecord(type, key);
    }

    checkAmount(edit: { ins?: Interface.In, outs?: Interface.Out }, mode: number) {
        //mode: -1 for remove record, 1 for add record
        let inAmount = this.state.ins.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
        let outAmount = this.state.outs.reduce((accumulator, currentValue) => accumulator + parseInt(currentValue.amount), 0);
        if (edit.ins) inAmount += parseInt(edit.ins.amount) * mode;
        if (edit.outs) outAmount += parseInt(edit.outs.amount) * mode;
        return inAmount >= outAmount;
    }

    render() {
        return (
            <div className="StockRecord" onClick={this.onClick}>
                <table><tbody>
                    <tr><td><div className="RecordContainer In">
                            <table>
                                <thead><tr><th colSpan={5}>入貨</th></tr></thead>
                                <tbody>
                                    <tr><td>日期</td><td>數量</td><td>入貨價</td><td>參考售價</td><td></td></tr>
                                    { this.state.ins.map((inRecord, i) => <Record onAddRecord={this.props.onAddRecord} checkAmount={this.checkAmount.bind(this)} onRemoveRecord={this.onRemoveRecord.bind(this, "in", i)} refIn={[]} in={ inRecord } type="in" key={i}/>) }
                                    <Record onAddRecord={this.props.onAddRecord} checkAmount={this.checkAmount.bind(this)} refIn={this.state.ins} type="in" />
                                </tbody>
                            </table>
                    </div></td></tr>
                    <tr><td><div className="RecordContainer Out">
                            <table>
                                <thead><tr><th colSpan={4}>出貨</th></tr></thead>
                                <tbody>
                                    <tr><td>日期</td><td>數量</td><td>售價</td><td></td></tr>
                                    { this.state.outs.map((outRecord, i) => <Record onAddRecord={this.props.onAddRecord} checkAmount={this.checkAmount.bind(this)} onRemoveRecord={this.onRemoveRecord.bind(this, "out", i)} refIn={[]} out={ outRecord } type="out" key={i}/>) }
                                    <Record onAddRecord={this.props.onAddRecord} checkAmount={this.checkAmount.bind(this)} refIn={this.state.ins} type="out"/>
                                </tbody>
                            </table>
                    </div></td></tr>
                </tbody></table>
            </div>
        )
    }
}
