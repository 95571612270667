import React from 'react';
import { BrowserRouter, Route } from 'react-router-dom';
import { Dashboard } from "./Components/Dashboard";
import { Interface } from "./Interface/sharedInterface";
import { LoginPanel } from './Components/LoginPanel';

export default class App extends React.Component<{}, Interface.User> {
    state: Interface.User = {
        isAuthencated: false
    }

    render() {
        return (
            <BrowserRouter>
                <div className="App">
                    <Route exact path="/"><Dashboard isAuth={this.state.isAuthencated}/></Route>
                    <Route exact path="/login"><LoginPanel authUser={() => {this.setState(prevState => ({...prevState, isAuthencated: true}))}}/></Route>
                </div>
            </BrowserRouter>
        );
    }
}
