import * as React from "react";
import "../Styles/Remarks.css";

export default class Remarks extends React.Component<{ content: string, onInputChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void }, {}> {

    onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
        e.stopPropagation();
    }

    renderRemarks() {
        return (
            <p className="RemarkText">
                { this.props.content }
            </p>
        )
    }

    renderEdit() {
        return (
            <div className="RemarkTextarea">
                <textarea value={ this.props.content } onChange={ this.props.onInputChange }>
                </textarea>
            </div>
        )
    }

    render() {
        return (
            <div className="Remarks" onClick={ this.onClick }>
                <h1>備註</h1>
                { !this.props.onInputChange ? this.renderRemarks() : this.renderEdit() }
            </div>
        )
    }
}
