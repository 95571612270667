import * as React from "react";
import "../Styles/InventoryContainer.css";
import { Item } from "./Item";
import { AddItem } from "./AddItem";
import { Interface } from "../Interface/sharedInterface";
import { DetailedInfo } from "./DetailedInfo";
import FirebaseManager from "../Script/FirebaseManager";

export class InventoryContainer extends React.Component<{ type: string, onReturn: (event: React.MouseEvent<HTMLButtonElement>) => void }, { items: Array<Interface.Item>, detailPopup: {show: boolean, type: string}, detailPopupInfo: Interface.Item }> {
    state = {
        items: [] as Array<Interface.Item>,
        detailPopupInfo: {
            id: "",
            name: "",
            source: "",
            size: "",
            weight: "",
            material: "",
            remarks: "",
            stockRecords: {
                in: [],
                out: []
            },
            img: ""
        },
        detailPopup: {
            show: false,
            type: ""
        }
    }

    private constructor(props: { type: string, onReturn: (event: React.MouseEvent<HTMLButtonElement>) => void }) {
        super(props);
        FirebaseManager.instance.toggleItemListener(props.type, true,
            async () => {
                let items = await FirebaseManager.instance.getItems(props.type);
                this.setState({ items: items });
            }
        )
    }

    componentWillUnmount() {
        FirebaseManager.instance.toggleItemListener(this.props.type, false);
    }

    async onAddItem(newItem: Interface.Item, imageFile: File) {
        return await FirebaseManager.instance.addItem(this.props.type, newItem, imageFile);
    }

    async onEditItem(editedItem: Interface.Item, imageFile?: File) {
        await FirebaseManager.instance.editItem(this.props.type, editedItem, imageFile);
    }

    async onDeleteItem(deleteItem: Interface.Item) {
        if (deleteItem.id !== undefined) await FirebaseManager.instance.deleteItem(this.props.type, deleteItem.id);
        this.closeDetailPopup();
    }

    onAddRecord(item: Interface.Item, edit: { ins?: Interface.In, outs?: Interface.Out }) {
        console.log(item);
        FirebaseManager.instance.updateRecord(this.props.type, item, edit);
    }

    onRemoveRecord(stockType: string, key: number, item: Interface.Item) {
        FirebaseManager.instance.removeRecord(this.props.type, item, stockType, key);
    }

    openDetailPopup(info: Interface.Item, mode: string) {
        this.setState({
            detailPopupInfo: info,
            detailPopup: {
                show: true,
                type: mode
            }
        })
    }

    closeDetailPopup() {
        this.setState({
            detailPopup: {
                show: false,
                type: ""
            }
        });
    }

    render() {
        return (
            <div id="InventoryContainer">
                <div><button type="button" id="returnButton" onClick={this.props.onReturn}>返回</button></div>
                { this.state.items.map(item =>
                    <Item item={ item } key={ item.id } openPopup={ this.openDetailPopup.bind(this, item, "DETAIL") }></Item>
                ) }
                <AddItem openPopup={
                    this.openDetailPopup.bind(this, {
                        id: "",
                        name: "",
                        source: "",
                        size: "",
                        weight: "",
                        material: "",
                        remarks: "",
                        stockRecords: {in:[], out:[]}, img: "" },
                        "NEW"
                    )
                }/>
                { this.state.detailPopup.show ?
                    <DetailedInfo
                        onClose={ this.closeDetailPopup.bind(this) }
                        itemInfo={ this.state.detailPopupInfo }
                        mode={ this.state.detailPopup.type }
                        onAddItem={ this.onAddItem.bind(this) }
                        onRemoveRecord={ this.onRemoveRecord.bind(this) }
                        onEditItem={ this.onEditItem.bind(this) }
                        onDeleteItem={ this.onDeleteItem.bind(this) }
                        onAddRecord={ this.onAddRecord.bind(this) }
                    /> : null
                }
            </div>
        )
    }
}
